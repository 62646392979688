@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@200;400;700&display=swap');
html body {
  margin: 0;
  color: #fff;
  background: #01152D;
  font-family: 'Outfit', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.3;
}
.reveal {
  opacity: 0;
  animation-play-state: paused;
}

.reveal.active {
  opacity: 1;
  animation-play-state: running;
}
@keyframes blur_xs {

  0%,
  15% {
    filter: blur(0)
  }

  25% {
    filter: blur(1px);
  }

  35% {
    filter: blur(2px)
  }

  45% {
    filter: blur(3px);
  }
}

@keyframes blur {
  0% {
    filter: blur(15px)
  }

  50% {
    filter: blur(30px);
  }

  25% {
    filter: blur(20px);
  }
}